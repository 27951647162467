import * as React from "react";
import allRoutes from "app/routes";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Drawer from "@mui/material/Drawer";
import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import appConfig from "configs/appConfig";
import { useNavigate } from "react-router-dom";
// Icons
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/auth/reducer";
import http from "utils/http";

const settings = ["Account", "Order", "Logout"];

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function HideAppBar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const { APP_NAME, color_pallete } = appConfig;
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenDrawer = () => setOpenDrawer(!openDrawer);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfileMenuNav = async (route) => {
    setAnchorElUser(null);
    if (route === "Logout") {
      dispatch(logout());
      try {
        await http.post("/logout");

        window.localStorage.removeItem("token");
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate(`/${route.toLowerCase()}`);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          sx={{
            bgcolor: color_pallete.primary,
            color: "white",
            paddingY: 1,
          }}
        >
          <Box sx={{ px: { xs: 3, md: 12 } }}>
            <Toolbar disableGutters>
              <Box
                component="img"
                src="/images/logo.png"
                alt="logo"
                sx={{
                  width: "70px",
                  display: { xs: "none", md: "block" },
                  mr: 1,
                }}
              />
              <Typography
                variant="h5"
                noWrap
                onClick={() => navigate("/")}
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontWeight: 700,
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {APP_NAME}
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenDrawer}
                  color="inherit"
                >
                  <MenuIcon sx={{ fontSize: 32 }} />
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1 }}>
                <Box
                  component="img"
                  src="/images/logo.png"
                  alt="logo"
                  sx={{ width: "110px" }}
                />
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {allRoutes
                  .filter((f) => {
                    return f.navbar === true;
                  })
                  .map((page, key) => (
                    <Button
                      key={`DeskNav-${key}`}
                      onClick={() => navigate(page.path)}
                      sx={{
                        my: 2,
                        color: "whiteSmoke",
                        display: "block",
                        fontSize: 15,
                        fontWeight: 500,
                        ":hover": {
                          fontWeight: 550,
                        },
                      }}
                    >
                      {page.name}
                    </Button>
                  ))}
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <SearchIcon sx={{ fontSize: 32 }} />
                </IconButton>
              </Box>

              <Box display="flex" gap={3}>
                {/* {!isMobile && (
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                )} */}

                {!isMobile && (
                  <Box sx={{ flexGrow: 0 }}>
                    {isAuthenticated ? (
                      <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar
                            alt={user?.name ?? "John"}
                            src="/static/images/avatar/2.jpg"
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Box display="flex" gap={2}>
                        <Button
                          variant="outlined"
                          size="large"
                          sx={{
                            border: `1px solid ${color_pallete.light}`,
                            fontSize: "inherit",
                            color: color_pallete.light,
                          }}
                          onClick={() => navigate("/register")}
                        >
                          Register
                        </Button>
                        <Button
                          variant="contained"
                          size="large"
                          sx={{ bgcolor: color_pallete.light }}
                          onClick={() => navigate("/login")}
                        >
                          Login
                        </Button>
                      </Box>
                    )}
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings.map((setting) => (
                        <MenuItem
                          key={setting}
                          onClick={() => handleProfileMenuNav(setting)}
                        >
                          <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                )}
              </Box>
            </Toolbar>
          </Box>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <Drawer anchor="top" open={openDrawer} onClose={handleOpenDrawer}>
        <Box sx={{ bgcolor: color_pallete.primary, color: "white", py: 3 }}>
          <Box display="flex" alignItems="center" flexDirection="column" mb={2}>
            <Box
              component="img"
              src="/images/logo.png"
              alt="logo"
              sx={{ width: "200px" }}
            />
            {/* <Typography variant="h4">Komodo Luxury Cruise</Typography> */}
          </Box>

          <List>
            {allRoutes
              .filter((f) => {
                return f.navbar === true;
              })
              .map((page, key) => (
                <ListItem
                  key={`MobileNav-${key}`}
                  sx={{ display: "flex", justifyContent: "center" }}
                  onClick={() => {
                    handleOpenDrawer();
                    navigate(page.path);
                  }}
                >
                  <Typography fontSize={16} fontWeight={550}>
                    {page.name}
                  </Typography>
                </ListItem>
              ))}
          </List>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
