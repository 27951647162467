import { lazy } from "react";

const Routes = [
  // DEFAULT PAGES
  {
    path: "/",
    component: lazy(() => import("pages/home/Page")),
    name: "Home",
    layout: true,
    navbar: true,
    authenticated: false,
  },
  /*
   ---------------------------------------------
    YOUR URL PAGES
   ---------------------------------------------
  */
  {
    path: "/packages",
    component: lazy(() => import("pages/packages/Page")),
    name: "Packages",
    layout: true,
    navbar: true,
    authenticated: false,
  },
  {
    path: "/itinerary/:id",
    component: lazy(() => import("pages/itinerary/Page")),
    name: "Detail Itinerary",
    layout: true,
    navbar: false,
    authenticated: false,
  },
  {
    path: "/packages/:slug",
    component: lazy(() => import("pages/package-detail/Page")),
    name: "Detail Package",
    layout: true,
    navbar: false,
    authenticated: false,
  },
  {
    path: "/package/:id",
    component: lazy(() => import("pages/packages/Page")),
    name: "List Package",
    layout: true,
    navbar: false,
    authenticated: false,
  },
  {
    path: "/blogs",
    component: lazy(() => import("pages/blog/Page")),
    name: "Blogs",
    layout: true,
    navbar: true,
    authenticated: false,
  },
  {
    path: "/blog-detail/:id",
    component: lazy(() => import("pages/blog-detail/Page")),
    name: "Blog Detail",
    layout: true,
    navbar: false,
    authenticated: false,
  },
  {
    path: "/about-us",
    component: lazy(() => import("pages/AboutUs")),
    name: "About Us",
    layout: true,
    navbar: true,
    authenticated: false,
  },
  {
    path: "/account",
    component: lazy(() => import("pages/account/Page")),
    name: "My Profile",
    layout: true,
    navbar: false,
    authenticated: true,
  },
  {
    path: "/order",
    component: lazy(() => import("pages/order/Page")),
    name: "History Order",
    layout: true,
    navbar: false,
    authenticated: true,
  },
  {
    path: "/complete-payment/wise",
    component: lazy(() => import("pages/complete-payment/Wise")),
    name: "Complete Payment",
    layout: true,
    navbar: false,
    authenticated: true,
  },
  {
    path: "/login",
    component: lazy(() => import("pages/auth/Login")),
    name: "Login",
    layout: true,
    navbar: false,
    authenticated: false,
  },
  {
    path: "/register",
    component: lazy(() => import("pages/auth/Register")),
    name: "Register",
    layout: true,
    navbar: false,
    authenticated: false,
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("pages/auth/Login")),
    name: "Forgot Password",
    layout: true,
    navbar: false,
    authenticated: false,
  },
  {
    path: "/verify-email/:token",
    component: lazy(() => import("pages/auth/VerifyEmail")),
    name: "Verify Email",
    layout: true,
    navbar: false,
    authenticated: false,
  },
  /*
    ---------------------------------------------
    PLEASE KEEP PUT IT AT THE BOTTOM
    ---------------------------------------------
  */
  {
    path: "*",
    component: lazy(() => import("pages/404")),
    name: "Page not found",
    layout: true,
    navbar: false,
    authenticated: false,
  },
];

export default Routes;
