import { Instagram, Twitter } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import appConfig from "configs/appConfig";
import React from "react";

const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const { color_pallete } = appConfig;

  return (
    <Box
      sx={{
        pb: { xs: 9, md: 3 },
        py: 2,
        bgcolor: color_pallete.primary,
        color: "whitesmoke",
      }}
    >
      <Typography textAlign="center" variant="h5" fontWeight="550" mb={1}>
        Follow Us
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Box
          component="a"
          sx={{ textDecoration: "none" }}
          href="https://www.instagram.com/komodoluxurycruise.id?igsh=MW5ncTY1MmtiNnNscw=="
        >
          <Instagram sx={{ fontSize: 28, color: "whitesmoke" }} />
        </Box>
        <Box
          component="a"
          href="https://www.tiktok.com/@komodoluxurycruise.id?_t=8j9uTSOOH57&_r=1"
        >
          <Box component="img" src="/images/tiktok.png" width={25} />
        </Box>
      </Box>
      <Typography
        textAlign="center"
        variant="h6"
        fontWeight="normal"
        fontSize={14}
      >
        &copy; Komodo Luxury Cruise {currentYear}
      </Typography>
    </Box>
  );
};

export default Footer;
