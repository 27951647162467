import React, { Suspense } from "react";
import AllRoutes from "./routes";
import { Helmet } from "react-helmet";
import { Routes, Route, useLocation } from "react-router-dom";
// @custome component
import MainBackdrop from "components/backdrop";
import Navbar from "layouts/Navbar";
// configs
import appConfig from "configs/appConfig";
import { isMobile } from "react-device-detect";
import MobileNav from "layouts/MobileNavBottom";
import MobileNavTop from "layouts/MobileNavTop";
import Footer from "layouts/Footer";
import { PrivateRoute } from "components/PrivateRoute";
import FloatingWhatsapp from "components/FloatingWhatsapp";

function App() {
  const { APP_NAME } = appConfig;
  const location = useLocation();
  const currentRoute = location.pathname;

  return (
    <Routes>
      {AllRoutes.map((val, key) => {
        let Components = val.component;

        if (val.layout === true) {
          return (
            <Route
              key={key}
              path={val.path}
              element={
                <>
                  <Helmet>
                    <title>
                      {val.name} - {APP_NAME}
                    </title>
                  </Helmet>
                  <Suspense fallback={<MainBackdrop open />}>
                    {isMobile && <MobileNav />}
                    {currentRoute !== "/" && isMobile ? (
                      <MobileNavTop routes={AllRoutes} />
                    ) : (
                      <Navbar />
                    )}
                    {!isMobile && <FloatingWhatsapp />}
                    {val.authenticated === true ? (
                      <PrivateRoute>
                        <Components />
                      </PrivateRoute>
                    ) : (
                      <Components />
                    )}
                    <Footer />
                  </Suspense>
                </>
              }
            />
          );
        } else {
          return (
            <Route
              key={key}
              path={val.path}
              element={
                <>
                  <Helmet>
                    <title>
                      {val.name} - {APP_NAME}
                    </title>
                  </Helmet>
                  <Suspense fallback={<MainBackdrop open />}>
                    {isMobile && <MobileNav />}
                    {currentRoute !== "/" && isMobile ? (
                      <MobileNavTop routes={AllRoutes} />
                    ) : null}
                    {val.authenticated === true ? (
                      <PrivateRoute>
                        <Components />
                      </PrivateRoute>
                    ) : (
                      <Components />
                    )}
                  </Suspense>
                </>
              }
            />
          );
        }
      })}
    </Routes>
  );
}

export default App;
