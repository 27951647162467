import {
  Cancel,
  Home,
  Person,
  ShoppingCart,
  WhatsApp,
} from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Modal,
  Typography,
} from "@mui/material";
import appConfig from "configs/appConfig";
import { history } from "helpers";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MobileNav = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { color_pallete } = appConfig;
  const [open, setOpen] = useState(false);

  const handleClickAccount = () => {
    if (isAuthenticated) {
      navigate("/account");
    } else {
      navigate("/login", { state: history.location });
    }
  };

  const handleWhatsapp = () => setOpen(!open);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: 100,
          borderTop: "0.5px solid gray",
        }}
      >
        <BottomNavigation
          sx={{ height: 65, bgcolor: color_pallete.primary }}
          showLabels
        >
          <BottomNavigationAction
            label="Home"
            icon={<Home sx={{ color: "white" }} />}
            onClick={() => navigate("/")}
          />
          <BottomNavigationAction
            label="Order"
            icon={<ShoppingCart sx={{ color: "white" }} />}
            onClick={() => navigate("/order")}
          />
          <BottomNavigationAction
            label="Account"
            icon={<Person sx={{ color: "white" }} />}
            onClick={handleClickAccount}
          />
          <BottomNavigationAction
            label="Whatsapp"
            onClick={handleWhatsapp}
            // LinkComponent="a"
            // href="https://api.whatsapp.com/send/?phone=%2B6282210963578&text=Halo&type=phone_number&app_absent=0"
            icon={<WhatsApp sx={{ color: "white" }} />}
          />
        </BottomNavigation>
      </Box>

      <Modal open={open} onClose={handleWhatsapp} sx={{ mx: 3 }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: "50%" },
            bgcolor: color_pallete.accent2,
            border: "none",
            boxShadow: 24,
            borderRadius: 3,
            p: 3,
            ":focus": {
              border: "none",
              outline: "none",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: -15,
              right: -10,
              cursor: "pointer",
            }}
            onClick={handleWhatsapp}
          >
            <Cancel
              sx={{
                fontSize: 32,
                color: "white",
                bgcolor: "gray",
                borderRadius: "50%",
              }}
            />
          </Box>
          <Typography
            variant="h3"
            textAlign="center"
            color="whitesmoke"
            mb={1.5}
          >
            Contact US
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              bgcolor: color_pallete.light,
              p: 1.5,
              borderRadius: 3,
              mb: 2,
            }}
          >
            <WhatsApp sx={{ color: "whitesmoke" }} />
            <Typography
              component="a"
              sx={{ textDecoration: "none", color: "whitesmoke" }}
              variant="h6"
              href="https://api.whatsapp.com/send/?phone=%2B6282210963578&text=Halo&type=phone_number&app_absent=0"
            >
              Admin 1 (Click here!)
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              bgcolor: color_pallete.light,
              p: 1.5,
              borderRadius: 3,
            }}
          >
            <WhatsApp sx={{ color: "whitesmoke" }} />
            <Typography
              component="a"
              sx={{ textDecoration: "none", color: "whitesmoke" }}
              variant="h6"
              href="https://api.whatsapp.com/send/?phone=%2B62818366601&text=Halo&type=phone_number&app_absent=0"
            >
              Admin 2 (Click here!)
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MobileNav;
