import { Cancel, WhatsApp } from "@mui/icons-material";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import appConfig from "configs/appConfig";
import React, { useState } from "react";

const FloatingWhatsapp = () => {
  const { color_pallete } = appConfig;
  const [open, setOpen] = useState(false);

  const handleWhatsapp = () => setOpen(!open);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 101,
          display: { xs: "none", md: "block" },
        }}
      >
        <IconButton
          onClick={handleWhatsapp}
          sx={{
            bgcolor: "green",
            width: "60px",
            height: "60px",
            ":hover": { bgcolor: "greenyellow" },
          }}
        >
          <WhatsApp sx={{ color: "whitesmoke", fontSize: "40px" }} />
        </IconButton>
      </Box>
      <Modal open={open} onClose={handleWhatsapp} sx={{ mx: 3 }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: "35%" },
            bgcolor: color_pallete.accent2,
            border: "none",
            boxShadow: 24,
            borderRadius: 3,
            p: 3,
            ":focus": {
              border: "none",
              outline: "none",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: -15,
              right: -10,
              cursor: "pointer",
            }}
            onClick={handleWhatsapp}
          >
            <Cancel
              sx={{
                fontSize: 32,
                color: "white",
                bgcolor: "gray",
                borderRadius: "50%",
              }}
            />
          </Box>
          <Typography
            variant="h3"
            textAlign="center"
            color="whitesmoke"
            mb={1.5}
          >
            Contact US
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              bgcolor: color_pallete.light,
              p: 1.5,
              borderRadius: 3,
              mb: 2,
            }}
          >
            <WhatsApp sx={{ color: "whitesmoke" }} />
            <Typography
              component="a"
              sx={{ textDecoration: "none", color: "whitesmoke" }}
              variant="h6"
              href="https://api.whatsapp.com/send/?phone=%2B6282210963578&text=Halo&type=phone_number&app_absent=0"
            >
              Admin 1 (Click here!)
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              bgcolor: color_pallete.light,
              p: 1.5,
              borderRadius: 3,
            }}
          >
            <WhatsApp sx={{ color: "whitesmoke" }} />
            <Typography
              component="a"
              sx={{ textDecoration: "none", color: "whitesmoke" }}
              variant="h6"
              href="https://api.whatsapp.com/send/?phone=%2B62818366601&text=Halo&type=phone_number&app_absent=0"
            >
              Admin 2 (Click here!)
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default FloatingWhatsapp;
