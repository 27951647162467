// eslint-disable-next-line import/no-anonymous-default-export
export default {
  APP_NAME: "Komodo Luxury Cruise",
  color_pallete: {
    primary: "#0A0D2E",
    secondary: "#BDAFB1",
    warning: "#E4C978",
    info: "#BBA87C",
    light: "#cc8b47",
    accent1: "1A1F4F",
    accent2: "#25316B",
    accent3: "#425A91",
    accent4: "#5A82B7",
    accent5: "#2E0A0D",
  },
};
