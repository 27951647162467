export function formatIDR(amount) {
  // Check if the input is a valid number
  if (typeof amount !== "number") {
    throw new Error("Invalid input. Please provide a number.");
  }

  // Convert the number to a string and split it into parts
  const parts = amount.toFixed(0).toString().split(".");

  // Format the main part with commas
  const formattedMain = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine the main part with the decimal part (if any) and add IDR symbol
  const formattedIDR = `IDR ${formattedMain}${parts[1] ? `.${parts[1]}` : ""}`;

  return formattedIDR;
}

export function formatDate(inputDate) {
  // Split the input date into year, month, and day
  const [year, month, day] = inputDate.split("-").map(Number);

  // Define month names in Indonesian
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Format the date in the desired format
  const formattedDate = `${monthNames[month - 1]} ${day}, ${year}`;

  return formattedDate;
}

export function formatCustomDate(isoDateString) {
  const isoDate = new Date(isoDateString);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = isoDate.getUTCMonth() + 1;
  const day = isoDate.getUTCDate();
  const year = isoDate.getUTCFullYear();

  return `${monthNames[month - 1]} ${day}, ${year}`;
}
