import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import appConfig from "configs/appConfig";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const getRouteName = (pathname, routes) => {
  const matchingRoute = routes.find((route) => route.path === pathname);

  if (matchingRoute) {
    return matchingRoute ? matchingRoute.name : null;
  } else {
    const route = pathname.split("/").slice(1, 2).join("/");

    if (route === "packages") return "Detail Package";
    if (route === "blogs") return "Blogs";
    if (route === "itinerary") return "Detail Itinerary";
    if (route === "blog-detail") return "Blog Detail";
  }
};

const MobileNavTop = (props) => {
  const { routes } = props;
  const { color_pallete } = appConfig;

  const navigate = useNavigate();
  const location = useLocation();
  const currentRouteName = getRouteName(location.pathname, routes);

  return (
    <Box
      display="flex"
      alignItems="center"
      p={3}
      bgcolor={color_pallete.primary}
    >
      <IconButton onClick={() => navigate(-1)}>
        <ArrowBack sx={{ color: "white" }} />
      </IconButton>
      <Typography mx="auto" variant="h5" color={"white"}>
        {currentRouteName}
      </Typography>
      <ArrowBack sx={{ opacity: 0 }} />
    </Box>
  );
};

export default MobileNavTop;
